import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { Logo } from './Logo'
import { decodeEntities } from '../utils/htmlParse'
import './HeaderNavigation.scss'

export const HeaderNavigation = ({ location, isOpen, closeNav }) => {
  const { headerNav } = useStaticQuery(graphql`
    query headerNavQuery {
      headerNav: wordpressWpApiMenusMenusItems(
        slug: { eq: "header-navigation" }
      ) {
        items {
          url
          title
          classes
          children: wordpress_children {
            url
            title
            classes
            children: wordpress_children {
              url
              title
              classes
            }
          }
        }
      }
    }
  `)
  if (!headerNav) return <nav className="header-navigation" />
  return (
    <nav className={`header-navigation ${isOpen ? 'open' : ''}`}>
      <div className="header-navigation-mobile-top">
        <Logo />
        <button
          type="button"
          className="menu-close-button"
          onClick={closeNav}
        />
      </div>
      <ul className="header-navigation-items">
        {headerNav.items.map((item, index) => {
          return (
            <li className="header-navigation-item" key={index}>
              <Link
                to={item.url}
                className={item.classes}
                activeClassName="active"
                state={{
                  prevPath:
                    location && location.location && location.location.pathname,
                }}
              >
                {decodeEntities(item.title)}
              </Link>
              {item.children && (
                <ul className="header-navigation-item-children">
                  {item.children.map((child, index) => {
                    return (
                      <li
                        key={index}
                        className={`header-navigation-item-child${
                          child.children ? ' has-children' : ''
                        }`}
                      >
                        <Link
                          to={child.url.replace('services_cat', 'services')}
                          className={child.classes}
                          activeClassName="active"
                          state={{
                            prevPath:
                              location &&
                              location.location &&
                              location.location.pathname,
                          }}
                        >
                          {decodeEntities(child.title)}
                        </Link>
                        {child.children && (
                          <ul className="header-navigation-item-children">
                            {child.children.map((subChild, index) => {
                              return (
                                <li
                                  className="header-navigation-item-child"
                                  key={index}
                                >
                                  <Link
                                    activeClassName="active"
                                    to={subChild.url.replace(
                                      'services_cat',
                                      'services'
                                    )}
                                    className={subChild.classes}
                                    state={{
                                      prevPath:
                                        location &&
                                        location.location &&
                                        location.location.pathname,
                                    }}
                                  >
                                    {decodeEntities(subChild.title)}
                                  </Link>
                                </li>
                              )
                            })}
                          </ul>
                        )}
                      </li>
                    )
                  })}
                </ul>
              )}
            </li>
          )
        })}
      </ul>
    </nav>
  )
}
