import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { decodeEntities } from '../utils/htmlParse'
import { Logo } from './Logo'
import './Footer.scss'
import iso45001Accreditation from '../images/accreditations/iso45001.svg'
import iso14001Accreditation from '../images/accreditations/iso14001.svg'
import iso9001Accreditation from '../images/accreditations/iso9001.svg'

const query = graphql`
  query footerQuery {
    footerNav: wordpressWpApiMenusMenusItems(
      slug: { eq: "footer-navigation" }
    ) {
      items {
        url
        title
        classes
        children: wordpress_children {
          url
          title
          classes
        }
      }
    }
    footerLegalNav: wordpressWpApiMenusMenusItems(
      slug: { eq: "legal-navigation" }
    ) {
      items {
        url
        title
        classes
      }
    }
    wordpressAcfOptions {
      options {
        locations {
          locationName
          addressStreet
          addressCity
          addressRegion
          addressPostCode
          phone
          faxNumber
          email
        }
        socialLinks {
          type
          url
        }
      }
    }
  }
`

export const Footer = ({ location }) => {
  const { footerNav, footerLegalNav, wordpressAcfOptions } = useStaticQuery(
    query
  )
  const { options = {} } = wordpressAcfOptions
  const { locations = null, socialLinks = [] } = options
  const date = new Date()
  const currentYear = date.getFullYear()
  const mainEmail =
    locations && locations.length > 0 ? locations[0].email : null
  const isHome = location.pathname === '/'
  const BirdBrainLink = () =>
    isHome ? (
      <a href="https://distl.com.au/" target="_blank" rel="noopener noreferrer">
        Distl
      </a>
    ) : (
      <span>Distl</span>
    )
  return (
    <footer>
      <div className="wide-wrapper">
        <div className="wrapper">
          <div className="footer-top">
            <Logo reversed />
            <div className="flex-wrap">
              {locations &&
                locations.map((location, index) => {
                  const {
                    locationName,
                    addressStreet,
                    addressCity,
                    addressRegion,
                    addressPostCode,
                    phone,
                  } = location
                  return (
                    <div className="contact-col" key={index}>
                      <span className="contact-col-heading">
                        {decodeEntities(locationName)}
                      </span>
                      <div className="contact-col-content">
                        {(addressStreet ||
                          addressCity ||
                          addressRegion ||
                          addressPostCode) && (
                          <p>
                            {decodeEntities(addressStreet)}
                            <br />
                            {decodeEntities(addressCity)},{' '}
                            {decodeEntities(addressRegion)},{' '}
                            {decodeEntities(addressPostCode)}
                          </p>
                        )}
                      </div>
                      <a href={`tel:${phone}`} className="contact-col-phone">
                        {phone}
                      </a>
                      {mainEmail && (
                        <div className="contact-methods-row">
                          <span className="contact-col-heading">Email</span>
                          <a
                            href={`mailto:${mainEmail}`}
                            className="contact-col-email"
                          >
                            {mainEmail}
                          </a>
                        </div>
                      )}
                    </div>
                  )
                })}
              {footerNav && (
                <nav className="footer-navigation">
                  <ul className="footer-navigation-items">
                    {footerNav.items.map((item, index) => {
                      return (
                        <li
                          key={index}
                          className={`footer-navigation-item ${item.classes}`}
                        >
                          <Link to={item.url}>
                            {decodeEntities(item.title)}
                          </Link>
                          {item.children && (
                            <ul>
                              {item.children.map((child, index) => {
                                return (
                                  <li
                                    className="footer-navigation-item-child"
                                    key={index}
                                  >
                                    <Link
                                      to={child.url}
                                      className={child.classes}
                                    >
                                      {decodeEntities(child.title)}
                                    </Link>
                                  </li>
                                )
                              })}
                            </ul>
                          )}
                        </li>
                      )
                    })}
                    <li className="footer-navigation-item col4">
                      <span>Follow Us</span>
                      {socialLinks && (
                        <ul>
                          {Object.keys(socialLinks).length > 0 &&
                            socialLinks?.map((social, index) => {
                              return (
                                <li
                                  className="footer-navigation-item-child"
                                  key={index}
                                >
                                  <a href={social.url}>{social.type}</a>
                                </li>
                              )
                            })}
                        </ul>
                      )}
                      <span className="accreditations-title">
                        Accreditations
                      </span>
                      <img
                        src={iso45001Accreditation}
                        alt="Accreditation badge"
                        className="accrediation-image"
                      />
                      <img
                        src={iso14001Accreditation}
                        alt="Accreditation badge"
                        className="accrediation-image"
                      />
                      <img
                        src={iso9001Accreditation}
                        alt="Accreditation badge"
                        className="accrediation-image"
                      />
                    </li>
                  </ul>
                </nav>
              )}
            </div>
          </div>
          <div className="footer-bottom">
            {/* eslint-disable-next-line */}
            <span className="credit">
              Website by <BirdBrainLink />
            </span>
            <div className="legalities">
              {footerLegalNav && (
                <nav className="legalities-nav">
                  <ul className="legalities-nav-items">
                    {footerLegalNav.items.map((item, index) => {
                      return (
                        <li className="legalities-nav-item" key={index}>
                          <Link to={item.url} className={item.classes}>
                            {decodeEntities(item.title)}
                          </Link>
                        </li>
                      )
                    })}
                    <span className="copyright">
                      &copy;{currentYear} Eastern Automation
                    </span>{' '}
                    {/* eslint-disable-line */}
                  </ul>
                </nav>
              )}
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
